.cliqueable {
  cursor: pointer;
}

/* Para que los radios y checkbox de sweet alert no aparezcan con foondo blanco en modo dark */
.swal2-radio,
.swal2-checkbox {
  background: #fff0 !important;
}

/* @media (min-width: 576px) and (max-width: 767px) { */
/* Estilos que se aplicarán solo en pantallas sm */
/* .container .row .col .card {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    color: rgb(253, 0, 42) !important;
  }
} */
