.loader-101 {
  width: 175px;
  height: 80px;
  background-image: radial-gradient(
      circle 25px at 25px 25px,
      #e0e0e0 100%,
      transparent 0
    ),
    radial-gradient(circle 50px at 50px 50px, #e0e0e0 100%, transparent 0),
    radial-gradient(circle 25px at 25px 25px, #e0e0e0 100%, transparent 0),
    linear-gradient(#e0e0e0 50px, transparent 0);
  background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  /* center screen */
  /* position: absolute;
  top: 50%;
  left: 40%; */
  /* center screen */
  position: fixed; /* para asegurarnos de que el elemento se mantenga centrado mientras se hace scroll */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; /* para asegurarnos de que el elemento aparezca encima de cualquier otro elemento */
}
.loader-101::after {
  content: '';
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  position: absolute;
  border: 15px solid transparent;
  border-top-color: #ff3d00;
  -webkit-animation: fadePush 1s linear infinite;
  animation: fadePush 1s linear infinite;
}
.loader-101::before {
  content: '';
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0);
  position: absolute;
  width: 15px;
  height: 15px;
  background: #ff3d00;
  -webkit-animation: fadePush 1s linear infinite;
  animation: fadePush 1s linear infinite;
}

.loader-102 {
  width: 175px;
  height: 80px;
  background-image: radial-gradient(
      circle 25px at 25px 25px,
      #e0e0e0 100%,
      transparent 0
    ),
    radial-gradient(circle 50px at 50px 50px, #e0e0e0 100%, transparent 0),
    radial-gradient(circle 25px at 25px 25px, #e0e0e0 100%, transparent 0),
    linear-gradient(#e0e0e0 50px, transparent 0);
  background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  /* center screen */
  position: absolute; /* para asegurarnos de que el elemento se mantenga centrado mientras se hace scroll */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* para asegurarnos de que el elemento aparezca encima de cualquier otro elemento */
}

.loader-102::after {
  content: '';
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0);
  position: absolute;
  border: 15px solid transparent;
  border-bottom-color: #ff3d00;
  -webkit-animation: fadePull 1s linear infinite;
  animation: fadePull 1s linear infinite;
}
.loader-102::before {
  content: '';
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  position: absolute;
  width: 15px;
  height: 15px;
  background: #ff3d00;
  -webkit-animation: fadePull 1s linear infinite;
  animation: fadePull 1s linear infinite;
}

.loader-101 span,
.loader-102 span {
  position: absolute;
  top: 120%;
  left: 50%;
  width: 280px;
  transform: translate(-50%, -50%);
  color: #53dd30;
  font-size: 20px;
  font-family: Arial, sans-serif;
}

/* keyFrames */
@-webkit-keyframes fadePush {
  0% {
    transform: translate(-50%, -15px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 15px);
    opacity: 0;
  }
}
@keyframes fadePush {
  0% {
    transform: translate(-50%, -15px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 15px);
    opacity: 0;
  }
}
@-webkit-keyframes fadePull {
  0% {
    transform: translate(-50%, 15px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -15px);
    opacity: 0;
  }
}
@keyframes fadePull {
  0% {
    transform: translate(-50%, 15px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -15px);
    opacity: 0;
  }
}
