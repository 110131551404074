@font-face {
  font-family: 'MeninBlue';
  src: url('../../fonts/MIB.ttf');
}
/* body{background-color:#fff} */
#auth {
  height: 100vh;
  overflow-x: hidden;
}
#auth #auth-right {
  background: url(../../../assets/images/4853433.png?45649b87e0b3f50bfa1372c6cdb4595f),
    linear-gradient(90deg, #c3e346, #c2f313);
  height: 100%;
}
#auth #auth-left {
  padding: 5rem 8rem;
}
#auth #auth-left .auth-title {
  font-size: 4rem;
  margin-bottom: 1rem;
}
#auth #auth-left .auth-subtitle {
  color: #a8aebb;
  font-size: 1.7rem;
  line-height: 2.5rem;
}
#auth #auth-left .auth-logo {
  margin-bottom: 7rem;
}
#auth #auth-left .auth-logo img {
  height: 2rem;
}
@media screen and (max-width: 767px) {
  #auth #auth-left {
    padding: 5rem;
  }
}
@media screen and (max-width: 576px) {
  #auth #auth-left {
    padding: 5rem 3rem;
  }
}
body.theme-dark #auth-right {
  background: url(../../../assets/images/4853433.png?45649b87e0b3f50bfa1372c6cdb4595f),
    linear-gradient(90deg, #c3e346, #c2f313);
}

/* Logo  */
.contenidoError {
  /* border: #000000 solid 1px; */
  position: relative;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout {
  display: inline-block;
  position: relative;
  margin-top: 0%;
  width: 50%;
}
